<template>
  <div class="dialogue wide">
    <h4 class="pb-5">Upload file(s)</h4>
    <div class="uploads"></div>
    <div class="text-right pt-5">
      <button @click="$emit('hideUploads')" class="button small red">Cancel</button>
    </div>
  </div>
</template>

<script>
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import Transloadit from '@uppy/transloadit';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import axios from '../axios';

export default {
  name: 'UploadFile',
  props: [
    'clientId',
    'modelId',
    'model',
    'type',
  ],
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  mounted() {
    const uppy = new Uppy({
      restrictions: {
        maxNumberOfFiles: 10,
        minNumberOfFiles: 1,
      },
    });
    uppy.use(Dashboard, {
      inline: true,
      width: '100%',
      target: '.uploads',
      proudlyDisplayPoweredByUppy: false,
    })
      .use(Transloadit, {
        service: 'https://api2.transloadit.com',
        params: {
          auth: { key: 'db6a1d40944f11e5a0a165fb32f6e49b' },
          template_id: '1896d036d8874cbe85fa470c0672fe80',
        },
        waitForEncoding: true,
        waitForMetadata: false,
        importFromUploadURLs: false,
        alwaysRunAssembly: false,
        signature: null,
        fields: {},
        limit: 0,
      })
      .on('complete', (results) => {
        const postData = {};
        postData.results = results;
        postData.model = this.model;
        postData.modelId = this.modelId;
        postData.clientId = this.clientId;
        postData.type = this.type;
        axios.post(`/files/create.json?token=${this.token}`, postData)
          .then((response) => {
            console.log(response.data);
            this.$emit('completeUploads', response.data);
          });
      });
  },
};
</script>

<style lang="scss">
.uppy-Dashboard-inner {
  max-height: 350px!important;
}
</style>
